<template>
  <div class="chooseNetwork-wrap ">
    <div class="chooseNetwork padding20" @click="popupShowFn">
      <p class=" main-text-2">{{ $t('chooseNetwork.network') }}</p>
      <div class="ub ub-btw margin-top8">
        <div class="main-text-3 " v-show="!chooseNetworkObj.network_type">{{ $t('chooseNetwork.slelectNetworkTip') }}</div>
        <div class="main-text-1 font14 font-weight500" v-show="chooseNetworkObj.network_type">{{ chooseNetworkObj.network_type }}</div>
        <div>
          <img src="@/assets/images/walltes/exchange.svg" alt="">
        </div>
      </div>
    </div>
    <van-popup v-model="popupShow" position="bottom">
      <div class="deposit-chooseNetwork-list background-color-4">
        <van-nav-bar left-text=" " >
          <p slot="left" class="font16 font-weight700">{{ $t('chooseNetwork.chooseNetwork') }}</p>
          <img
            @click="onCancel"
            slot="right"
            src="@/assets/images/system/cross.svg"
            alt="close"
            title="close"
          />
        </van-nav-bar>
        <div class="chooseNetwork-main">
          <!-- <div class="padding-left20 padding-right20 padding-top20 main-text-3">
            {{ $t('chooseNetwork.tip') }}
          </div> -->
          <ul class="list-box padding20">
            <li class="padding16 background-color-2 margin-top8 font14" v-for="(item,index) in filterchooseNetwork" :key="index" @click="onConfirm(item)">
              <span>
                {{ item.network_type }}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { fundNetworkList } from '@/api/assetsCenter.js'
import { Popup, Picker, Icon } from 'vant'
import Vue from 'vue'

Vue.use(Popup)
Vue.use(Picker)
Vue.use(Icon)

export default {
  name: 'chooseNetwork',
  props: {
  },
  data () {
    return {
      value: '',
      columns: [
      ],
      chooseNetworkObj: {},
      popupShow: false
    }
  },
  computed: {
    filterchooseNetwork () {
      let list = []
      if (this.value) {
        list = this.columns.filter((item) => {
          return item.chooseNetwork.indexOf(this.value) !== -1
        })
      } else {
        list = this.columns
      }
      return list
    }
  },
  mounted () {
    this.fundNetworkListFn()
  },
  methods: {
    fundNetworkListFn () {
      fundNetworkList({}).then(res => {
        console.log(res, 'res=======')
        this.columns = res.data
        this.chooseNetworkObj = this.columns[0]
        this.$emit('callback', this.chooseNetworkObj)
        // this.$toast.clear()
      })
    },
    clearSearch () {
      this.value = ''
    },
    popupShowFn () {
      this.popupShow = true
    },
    onConfirm (value) {
      this.popupShow = false
      this.chooseNetworkObj = value
      this.$emit('callback', value)
    },
    onCancel () {
      this.popupShow = false
    }
  }
}
</script>
<style lang="less">
.chooseNetwork-wrap{
  .chooseNetwork{
    .icon-box{
      width: 80px;
      height: 40px;
    }
  }
}
.van-popup{
  background-color: rgba(0, 0, 0, 0);
}
.deposit-chooseNetwork-list{
  width: 100%;
  height: 40vh;
  overflow: hidden;
  border-top-left-radius: 5vw;
  border-top-right-radius: 5vw;
  .chooseNetwork-main{
    height: 30vh;
    overflow: scroll;
    .list-box{
      box-sizing: border-box;
      overflow: scroll;
      li{
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 8px;
      }
    }
  }
}
</style>
