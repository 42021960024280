<template>
  <div class="deposit-info-wrap">
    <DepositInfoCpt></DepositInfoCpt>
  </div>
</template>
<script>
import DepositInfoCpt from '@/components/deposit/depositInfo/index.vue'
export default {
  name: 'DepositInfo',
  components: { DepositInfoCpt },
  computed: {
  },
  methods: {
    back () {
      this.$router.back()
    }
  }
}
</script>
