<template>
  <van-pull-refresh v-model="isLoading" :loosing-text="$t('pullRefresh.loosingText')" :pulling-text="$t('pullRefresh.pullingText')" :loading-text="$t('pullRefresh.loadingText')" @refresh="onRefresh">
    <slot></slot>
  </van-pull-refresh>
</template>
<script>
import Vue from 'vue'
import { PullRefresh } from 'vant'

Vue.use(PullRefresh)
export default {
  components: { },
  computed: {
  },
  data () {
    return {
      isLoading: false,
      timer: null
    }
  },
  beforeDestroy () {
    clearTimeout(this.timer)
    this.timer = null
  },
  methods: {
    onRefresh () {
      this.$emit('refreshFn')
      this.timer = setTimeout(() => {
        this.isLoading = false
      }, 500)
    }
  }
}
</script>
