<template>
  <div class="deposit-info-wrap">
    <van-nav-bar :border="false" left-text="" @click-left="back" left-arrow>
      <div slot="left">
        <img src="@/assets/images/system/left-arrow.svg"/>
      </div>
      <div slot="right">
        <img @click="goHistory" src="@/assets/images/walltes/list.svg" alt="" srcset="">
      </div>
    </van-nav-bar>
    <div class="deposit-info-main-wrap">
      <PullRefresh @refreshFn="refreshFn">
        <div class="deposit-info-main ">
          <div class="font24 font-weight500 title-color padding20">
            {{this.$t('deposit.deposit')}} {{coinInfo.label}}
          </div>
          <div class="QRCode-wrap padding20">
            <div class="QRCode-box margin-top20 margin-bottom20 background-color-2">
              <img v-if="qrCode" :src="qrCode" alt="">
            </div>
            <div class="tip text-c main-text-2">
              <div class="tip1">
                {{ $t('deposit.depositTip1') }} {{coinInfo.label}} {{ $t('deposit.depositTip11') }}
              </div>
              <!-- <div class="tip2">
                {{ $t('deposit.depositTip2') }}
              </div> -->
            </div>
          </div>
          <div class="content-box">
            <ChooseNetwork ref="ChooseNetwork" @callback="getNetwork"></ChooseNetwork>
            <div class="padding20" v-show="network.network_type">
              <p class=" main-text-2">{{coinInfo.label}} {{ $t('deposit.deposit') }} {{ $t('deposit.address') }}</p>
              <div class="ub ub-btw margin-top8">
                <div class="main-text-1 font14 font-weight500 break-word" style="width: 80%;">{{ depositAddress }}</div>
                <div>
                  <CopyWrap :text="depositAddress">
                    <img src="@/assets/images/profile/copy.svg"/>
                  </CopyWrap>
                </div>
              </div>
            </div>
            <!-- <div class="padding20"  v-show="network.chooseNetwork">
              <p class=" main-text-2">{{ $t('deposit.selectedWallet') }}</p>
              <div class="ub ub-btw padding20">
                <div class="main-text-1">{{ $t('deposit.spotWallet') }}</div>
              </div>
            </div> -->
            <div class="rule-box margin20 background-color-2"  v-show="network.network_type">
              <div class="ub ub-btw padding12">
                <div class="main-text-2"> {{ $t('deposit.minimum') }} </div>
                <div class="main-text-1">  0.0001 {{coinInfo.label}}  </div>
              </div>
              <div  class="ub ub-btw padding12">
                <div class="main-text-2"> {{ $t('deposit.expectedArrival') }} </div>
                <div class="main-text-1"> 15 {{ $t('deposit.networkConfirmations') }}  </div>
              </div>
              <div class="ub ub-btw padding12">
                <div class="main-text-2"> {{ $t('deposit.expectedUnlock') }} </div>
                <div class="main-text-1"> 15 {{ $t('deposit.networkConfirmations') }} </div>
              </div>
              <!-- <div class="ub ub-btw padding12">
                <div class="main-text-2"> {{ $t('deposit.contractInformation') }} </div>
                <div class="main-text-1"> 151asdas </div>
              </div> -->
            </div>
            <!-- <div class="footer-btn-box ub ub-btw text-c" v-show="network.chooseNetwork">
              <div class="btn-box background-color-3 font16">
                {{ $t('deposit.saveAsImage') }}
              </div>
              <div class="btn-box primary-btn-color main-text-1 font16">
                {{ $t('deposit.shareAddress') }}
              </div>
            </div> -->
          </div>
        </div>
      </PullRefresh>
    </div>
  </div>
</template>
<script>
import { fundDeposit } from '@/api/assetsCenter.js'
import PullRefresh from '@/components/pullRefresh/index.vue'
import ChooseNetwork from '@/components/deposit/chooseNetwork/index.vue'
import CopyWrap from '@/components/copy/index.vue'
import Vue from 'vue'
import { NavBar } from 'vant'

Vue.use(NavBar)
export default {
  components: { PullRefresh, ChooseNetwork, CopyWrap },
  computed: {
  },
  data () {
    return {
      coinInfo: {},
      network: {},
      qrCode: '',
      depositAddress: ''
    }
  },
  mounted () {
    this.coinInfo = this.$route.query
    console.log(this.$route.query)
    // this.fundDepositFn()
  },
  methods: {
    refreshFn () {
      this.$refs.ChooseNetwork.fundNetworkListFn()
      // this.fundDepositFn()
    },
    goHistory () {
      this.$router.push({
        path: '/history/walltes/deposit'
      })
    },
    fundDepositFn () {
      const networkType = this.network.network_type
      fundDeposit({
        network_type: networkType
      }).then(res => {
        console.log(res, 'fundDepositFn ------')
        this.qrCode = res.data.qr_code
        this.depositAddress = res.data.address
        this.$toast.clear()
      })
    },
    getNetwork (value) {
      this.network = value
      this.fundDepositFn()
      console.log(value)
    },
    back () {
      this.$router.back()
    },
    copyTextToClipboard (text) {

    }
  }
}
</script>
<style lang="less">
.deposit-info-main-wrap{
  background-color: #fff;
  // padding-bottom: 200px;
  height: calc(100vh - 13.33333vw);
  overflow: scroll;
  box-sizing: border-box;
}
.deposit-info-main{
  background-color: #fff;
  // padding-bottom: 200px;
  // height: calc(100vh - 13.33333vw);
  // overflow: scroll;
  box-sizing: border-box;
}
.content-box{
  border-top: 1px solid var(--border-color-1);
  .rule-box{
    border-radius: 8px;
  }
  .footer-btn-box{
    width: 100%;
    background-color: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 40px;
    box-sizing: border-box;
    .btn-box{
      box-sizing: border-box;
      padding: 24px 0;
      width: 47%;
      border-radius: 8px;
    }
  }
}
.QRCode-wrap{
  .QRCode-box{
    margin-left: auto;
    margin-right: auto;
    width: 40vw;
    height: 40vw;
    img{
      width: 100%;
      height: 100%;
    }
  }
}
</style>
